<!--
File: Settings.vue
Description: form for showing/editing global settings.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
            v-model="searchQuery"></md-input>
        </md-field>
      </div>
      <!--div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="settingProfile"> {{ $t('buttons.add') }} </md-button>
        </div-->
    </md-card-header>
    <md-card-content>
      <md-table :value="queriedData" @md-selected.native="onSelect" :md-sort.sync="currentSort" md-fixed-header
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
        <md-table-empty-state md-label="No data to show"
          :md-description="`Try to select a region or another search criteria.`" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('translate.key')" md-sort-by="key">
            <span v-if="!isSelectedRow(item)">{{ item.key }}</span>
            <div v-else class="md-layout-item md-small-size-100 md-size-50">
              <md-field :class="[
                { 'md-valid': !errors.has('key') },
                { 'md-error': errors.has('key') }
              ]">
                <md-input v-model="edit_row.key" type="edit_row.key" :data-vv-name="edit_row.key" required
                  :v-validate="modelValidations.key"></md-input>
              </md-field>
            </div>
          </md-table-cell>
          <md-table-cell :md-label="$t('settings.value')" md-sort-by="value">
            <span v-if="!isSelectedRow(item)">{{ item.value }}</span>
            <div v-else class="md-layout-item md-small-size-100 md-size-50">
              <md-field :class="[
                { 'md-valid': !errors.has('value') },
                { 'md-error': errors.has('value') }
              ]">
                <md-input v-model="edit_row.value" type="text" :data-vv-name="edit_row.value" required
                  :v-validate="modelValidations.value"></md-input>
              </md-field>
            </div>
          </md-table-cell>

          <md-table-cell class="btn-cont" :md-label="$t('tables.actions')">
            <div class="cell-actions">
              <template v-if="!isSelectedRow(item)">
                <md-button class="md-raised md-sm md-primary" @click.stop.prevent="onEditRow(item)">
                  {{ $t('buttons.edit') }}
                </md-button>
              </template>
              <template v-else>
                <md-button class="md-success md-raised md-sm" @click.stop.prevent="onSave(item)">
                  {{ $t('buttons.save') }}
                </md-button>
                <md-button class="md-default md-raised md-sm" @click.stop.prevent="onCancel()">
                  {{ $t('buttons.cancel') }}
                </md-button>
              </template>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item.name" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
  import { Pagination } from '@/components'
  import Fuse from 'fuse.js'
  // import Swal from 'sweetalert2'

  export default {
    name: 'settings',

    data() {
      return {
        selected_category: null,
        edit_row: { value: null, key: null },
        currentSort: 'key',
        currentSortOrder: 'asc',
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        footerTable: [],
        searchQuery: '',
        propsToSearch: ['key', 'value'],
        searchedData: [],
        fuseSearch: null,
        categories: [],
        modelValidations: {
          key: {
            required: true,
            min: 3
          },
          value: {
            required: true,
            min: 5
          }
        }
      }
    },
    components: {
      Pagination,
    },
    created() {
      this.$store.dispatch('LOAD_SETTINGS').then(() => {
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ['key'],
          threshold: 0.3
        })
      })
    },

    methods: {
      /*
              settingProfile() {
              this.$router.push('translate_add')
            },
      */
      isSelectedRow(item) {
        if (!this.edit_row) {
          return false
        }
        return (
          item.key === this.edit_row.key &&
          item.value === this.edit_row.value
        )
      },
      //      onCategoryChange() {},
      onEditRow(item) {
        const { key, value } = item
        this.edit = { key, value }
        this.edit_row = { key: item.key, value: item.value }
      },
      customSort(value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          const res = (!a[sortBy]) ? -1 : (!b[sortBy]) ? 1 :
            a[sortBy].toString().localeCompare(b[sortBy].toString(), undefined, { numeric: true })
          return (this.currentSortOrder === 'desc') ? -res : res
        })
      },
      onSave() {
        /*        const {key, value} = this.edit_row
                this.$store
                  .dispatch('UPD_TRANSLATE_ENTRY', {
                    ...this.edit_row,
                    item: {...this.edit, key, category}
                  })
                  .then(() => {
                    this.edit_row = {category: null, key: null}
                  })
    
        */
      },
      onCancel() {
        this.edit_row = { value: null, key: null }
      }
    },

    computed: {
      settingsList() {
        return this.$store.state.Settings.list
      },
      queriedData() {
        const result = !this.searchQuery ? this.tableData : this.searchedData //? this.searchedData : []
        return result.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total() {
        return this.searchQuery ? this.searchedData.length : this.tableData.length
      },
      tableData() {
        return this.settingsList
      }
    },

    watch: {
      searchQuery(value) {
        if (this.settingsList.length == 0) {
          this.searchedData = []
          return
        }
        this.searchedData = (value !== '') ? this.fuseSearch.search(this.searchQuery) : this.tableData
      },
      edit_row() {
        this.key === '' && this.value === ''
        /*            this.lang = {
                        key: '',
                        value: ''
                    }*/
      }
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>